button:focus {outline:0 !important; box-shadow: none !important;}

body {
  background-color: #F7FAFC !important;
}

.swiper {
  padding-bottom: 30px;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-pagination {
  bottom: 0 !important;
}